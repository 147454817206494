import React, { useState, useContext } from 'react';
import { Table, Button, Divider } from 'antd';
import { createBrowserHistory } from 'history';
import { Title, TableSearch, Loader } from './common';
import useApi from '../hooks/useApi';
import { getRequest } from '../utils/network';
import { UserContext } from '../contexts/UserContext';
import { NULL_UUID } from '../utils/constants';

const history = createBrowserHistory({
  forceRefresh: true
});

const pageSize = 50;
const listURL = `/locations?per_page=${pageSize}`;

const LocationList = props => {
  const [pagination, setPagination] = useState({ current: 1, pageSize });
  const { data, isLoading } = useApi(listURL);
  const [dataSource, setDataSource] = useState(data);
  const [hasSearch, setHasSearch] = useState(false);
  const [user, setUser] = useContext(UserContext);

  if (isLoading) {
    return <Loader />;
  }

  const { locations, count } = data;

  const changePage = (page, pageSize) => {
    getRequest(`${listURL}&page=${page}`).then(({ data }) => {
      setHasSearch(true);
      setDataSource(data.locations);
      setPagination({ ...pagination, current: page });
    });
  };

  const handleChange = (p, filters, sorter) => {
    changePage(p.current, p.pageSize);
  };

  const handleSearch = searchText => {
    setHasSearch(true);

    const results = locations.filter(({ name }) => {
      name = name.toLowerCase();
      return name.includes(searchText.toLowerCase());
    });

    setDataSource(results);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, location) => (
        <Button type="link" style={{ fontSize: '1rem' }} href={`/locations/${location.id}`}>
          {text}
        </Button>
      )
    },
    {
      title: 'Address',
      dataIndex: 'address_display',
      render: addr => addr.split('\\n').join(' '),
      key: 'address'
    },
    {
      title: 'Business',
      dataIndex: 'business_id',
      render: business_id =>
        business_id == undefined || business_id === NULL_UUID ? null : (
          <Button type="link" icon="shop" style={{ fontsize: '1rem' }} href={`/businesses/${business_id}`}>
            Business
          </Button>
        ),
      key: 'business_id'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, location) => (
        <>
          <Button type="link" icon="eye" onClick={() => history.push(`/locations/${location.id}`)} />
          {user.role === 100 || user.id === location.owner_id || location.manager_id ? (
            <>
              <Divider type="vertical" />
              <Button type="link" icon="edit" onClick={() => history.push(`/locations/${location.id}/edit`)} />
            </>
          ) : null}
          {location.email == undefined || location.email === '' ? null : (
            <>
              <Divider type="vertical" />
              <Button type="link" icon="mail" onClick={() => (window.location.href = `mailto:${location.email}`)} />
            </>
          )}
        </>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={hasSearch ? dataSource : locations}
      title={() => (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-around',
            padding: '0.5rem 0'
          }}
        >
          <Title style={{ marginLeft: 0, marginBottom: 0, fontWeight: 400 }}>Locations</Title>
          {/* revisit this at a later date re: building */}
          {/* an elastic search field for businesses and locations */}
          {/* <TableSearch onSearch={handleSearch} /> */}
          {user.role == 100 ? (
            <Button
              size="large"
              style={{ float: 'right', margin: '0 1rem' }}
              type="primary"
              onClick={() => history.push('/add-business')}
            >
              Add Business
            </Button>
          ) : null}
        </div>
      )}
      pagination={{
        ...pagination,
        total: count
      }}
      onChange={handleChange}
    />
  );
};

export default LocationList;
