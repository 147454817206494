import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Card, Form, Input } from 'antd';
import { postRequest } from '../utils/network';
import { TOKEN } from '../utils/constants';
import { isTokenValid } from '../utils/auth';
import { UserContext } from '../contexts/UserContext';
import { Button, Heading, Subtitle } from './common';

const Login = props => {
  const {
    form,
    form: { getFieldDecorator }
  } = props;
  const [user, setUser] = useContext(UserContext);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      postRequest('/login', values).then(response => {
        const token = response.headers[TOKEN];
        const role = response.data.role;
        const userId = response.data.id;
        let loggedIn = false;
        if (isTokenValid(token)) {
          localStorage.setItem(TOKEN, token);
          localStorage.setItem('role', role);
          localStorage.setItem('userId', userId);
          loggedIn = true;
        }
        console.log(values);

        setUser({ ...user, loggedIn, role, userId });
      });
    });
  };

  if (user.loggedIn) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  const styles = {
    loginContainer: {
      backgroundColor: 'transparent',
      border: 'none',
      marginTop: '15vh',
      width: '425px'
    }
  };

  return (
    <Card style={styles.loginContainer}>
      <Heading
        style={{
          color: 'white',
          textAlign: 'left',
          fontWeight: 600,
          fontSize: '3.25rem',
          lineHeight: '1.4'
        }}
      >
        Sign In
      </Heading>
      <Subtitle
        style={{
          color: 'white',
          textAlign: 'left',
          padding: 0,
          marginBottom: '1.5rem'
        }}
      >
        Please sign in to access Props for Business.
      </Subtitle>
      <Form onSubmit={handleSubmit}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please enter your Email' }]
          })(<Input size="large" type="text" placeholder="Email" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please enter your Password' }]
          })(<Input size="large" type="password" placeholder="Password" />)}
        </Form.Item>
        <Button size="large" variant="primary" htmlType="submit">
          Sign In
        </Button>
      </Form>
    </Card>
  );
};

export default Form.create()(Login);
