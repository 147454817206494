import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, height, width } from 'styled-system';
import Link from './Link';
import logo from '../../logo.png';

const propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
};

const Image = styled.img`
  ${space}
  ${width}
  ${height}
`;

Image.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...height.propTypes
};

function Logo({ children, onClick, height, ...props }, ref) {
  return (
    <>
      <Link
        className="header__logo logo"
        style={{ cursor: 'pointer' }}
        onClick={onClick}
        children={children}
        space={space}
        href="/"
      >
        <Image src={logo} alt="logo" className="App-logo" height={height} />
      </Link>
    </>
  );
}

Logo.propTypes = propTypes;

export default Logo;
