import { notification } from 'antd';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  forceRefresh: true
});

export function IsNotEmpty(obj) {
  return obj && obj !== 'null' && obj !== 'undefined';
}

export function ExtractData(o) {
  Object.values(o)
    .filter(obj => Array.isArray(obj))
    .flat(1);
  return o;
}

export function notificationRedirect(redirectUrl, id) {
  notification.success({
    message: 'Success',
    description: 'Your changes have been successfully saved.',
    onClick: () => {
      console.log('Notification Clicked!');
    }
  });
  setTimeout(() => history.push(redirectUrl + `/${id}`), 1000);
}
