import React from 'react';
import { Row } from 'antd';
import { Text, theme } from './common';

const AppFooter = () => {
  const styles = {
    footer: {
      backgroundColor: '#11141e',
      padding: '1.5rem',
      height: 90
    },
    copy: {
      color: theme.colors.moonGray,
      fontWeight: 600,
      fontSize: 14
    }
  };

  return (
    <footer>
      <Row type="flex" align="middle" justify="center" style={styles.footer}>
        <Text style={styles.copy}>&copy; 2019 Props Love. All Rights Reserved.</Text>
      </Row>
    </footer>
  );
};

export default AppFooter;
