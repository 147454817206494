import React, { useState, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import useApi from '../hooks/useApi';
import { putRequest } from '../utils/network';
import { Avatar, Icon, Button, Row, Col, Divider, Tag, Layout, Table, Modal } from 'antd';
import { Box, Flex, Heading, HeroBg, Subtitle, Title, Text, theme, Loader } from './common';
import { IsNotEmpty } from '../utils/helpers';

const history = createBrowserHistory({
  forceRefresh: true
});

const { Content } = Layout;
const { confirm } = Modal;

const moment = require('moment');

const LocationDetail = ({ match }) => {
  const { data, isLoading } = useApi(`/locations/${match.params.locationId}`);
  // const [dataSource, setDataSource] = useState(data);
  const [recs, setRecs] = useState([]);
  const [_, setRefreshTrigger] = useState('');

  useEffect(() => {
    if (data != undefined) {
      setRecs(data.recommendations);
    }
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  const displayManager = manager => `${manager.first_name} ${manager.last_name}`;
  const displayEmployee = employee => `${employee.first_name} ${employee.last_name}`;
  const joinedDisplay = time => `${moment(time).format('MMM DD, YYYY')}`;
  const heroImage = data.image_url ? data.image_url : 'https://propslove.com/images/section-bg1.jpg';
  const avatarImage = data.secondary_image_url ? data.secondary_image_url : '';

  const styles = {
    heroBg: {
      height: '35vh',
      backgroundImage: `url(${heroImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-end',
      marginBottom: '1.5rem'
    },
    businessOwnerTitle: {
      fontWeight: 500,
      fontSize: '0.875rem',
      color: theme.colors.blacks[7],
      marginLeft: '0.5rem'
    },
    tagBusinessType: {
      fontWeight: 600,
      verticalAlign: 'middle',
      marginLeft: '0.25rem'
    },
    tagPositionType: {
      fontWeight: 600,
      verticalAlign: 'middle',
      marginLeft: '0.25rem'
    },
    tableTitleContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      padding: '0.5rem 0'
    },
    tableTitleText: {
      marginLeft: 0,
      marginBottom: 0,
      fontWeight: 400
    },
    tableFeaturedBtn: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      boxShadow: 'none'
    },
    tableFeaturedIcon: {
      fontSize: '1.5rem'
    },
    propTag: {
      fontSize: 12,
      fontWeight: 600
    }
  };

  let employees = [],
    pendingEmployees = [],
    generalRecs = [],
    featuredRecs = [];

  if (IsNotEmpty(employees)) {
    Object.assign(employees, data.employees);
    Object.assign(pendingEmployees, data.pending_employees);

    employees.forEach(function(employee) {
      employee.isApproved = 'Approved';
    });

    pendingEmployees.forEach(function(employee) {
      employee.isApproved = 'Pending';
    });

    Array.prototype.push.apply(employees, pendingEmployees);
  }

  if (IsNotEmpty(recs)) {
    if (data.owner_id !== '' && data.owner_id != undefined) {
      Object.assign(featuredRecs, data.featured_recommendations);
    }
  }

  if (IsNotEmpty(generalRecs)) {
    Object.assign(generalRecs, data.general_recommendations);
  }

  const isClaimed = owner_id => (
    <Icon
      type="check-circle"
      style={{ fontSize: '1.125rem' }}
      theme="twoTone"
      twoToneColor={owner_id === null ? theme.colors.moonGray : theme.colors.blue}
    />
  );

  const isPremium = premium => (
    <Icon
      type="dollar"
      style={{ fontSize: '1.125rem' }}
      theme="twoTone"
      twoToneColor={premium ? theme.colors.green : theme.colors.moonGray}
    />
  );

  const avatar = () =>
    avatarImage !== '' ? <Avatar size={100} shape="circle" src={avatarImage} /> : <Avatar size={100} icon="user" />;

  const detailHeader = () => (
    <>
      <Box style={styles.heroBg} />
      <Box pt={[3, 4]} px={[2, 3]}>
        <Flex flexDirection="row" justifyContent="space-even" align="start">
          <Flex style={{ width: 'auto' }} flexDirection="column">
            {avatar()}
            <Button
              size="small"
              style={{ marginTop: '0.5rem' }}
              onClick={() => history.push(`/locations/${match.params.locationId}/edit`)}
            >
              Edit Location
            </Button>
          </Flex>
          <Box px={[2, 3]}>
            <Heading>
              {data.name}
              <Tag style={styles.tagBusinessType} color={theme.colors.primary}>
                {data.type !== null ? data.type.toUpperCase() : 'Add Location Type'}
              </Tag>
              {isClaimed(data.owner_id)} {isPremium(data.business.premium)}
            </Heading>
            {data.address.id != '00000000-0000-0000-0000-000000000000' ? (
              <>
                <Text>
                  <Text.span>
                    <Icon type="environment" />
                    <Text.span style={{ marginLeft: '0.75rem' }}>{data.address.address_1}</Text.span>,{' '}
                    <Text.span>{data.address.address_2}</Text.span>
                  </Text.span>
                </Text>
                <Text style={{ marginLeft: '1.75rem' }}>
                  <Text.span>{data.address.city}, </Text.span>
                  <Text.span>{data.address.state} </Text.span>
                  <Text.span>{data.address.postal_code}</Text.span>
                </Text>
              </>
            ) : null}
          </Box>
          {data.manager != null ? (
            <Box style={{ marginLeft: 'auto' }}>
              <Subtitle style={{ display: 'inline-block', textAlign: 'left' }}>
                {displayManager(data.manager)}
                <Tag style={styles.tagPositionType}>Manager</Tag>
              </Subtitle>
              <Text>
                <Text.span>
                  <Icon type="mail" key="mail" />
                  <Text.span style={{ marginLeft: '1rem' }}>{data.manager.email}</Text.span>
                </Text.span>
              </Text>
              <Text>
                <Text.span>
                  <Icon type="phone" key="phone" />
                  <Text.span style={{ marginLeft: '1rem' }}>{data.manager.phone}</Text.span>
                </Text.span>
              </Text>
            </Box>
          ) : (
            <Box />
          )}
        </Flex>
      </Box>
    </>
  );

  const approveEmployee = id => {
    putRequest(`/locations/${match.params.locationId}/update_employee/${id}`, {
      approved: true
    }).then(() => history.push(`/locations/${match.params.locationId}`));
  };

  function confirmApproval(id) {
    confirm({
      icon: <Icon type="warning" theme="twoTone" twoToneColor={theme.colors.yellow} />,
      title: 'Are you sure?',
      content: 'You are about to approve this user as an employee.',
      okText: 'Confirm',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk() {
        approveEmployee(id);
      },
      onCancel() {}
    });
  }

  const rejectEmployee = id => {
    putRequest(`/locations/${match.params.locationId}/update_employee/${id}`, {
      approved: false
    }).then(() => history.push(`/locations/${match.params.locationId}`));
  };

  function confirmRejection(id) {
    confirm({
      icon: <Icon type="warning" theme="twoTone" twoToneColor={theme.colors.yellow} />,
      title: 'Are you sure?',
      content: 'You are about to reject (or remove) this user as an employee.',
      okText: 'Confirm',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk() {
        rejectEmployee(id);
      },
      onCancel() {}
    });
  }

  const employeesCol = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (text, employee) => displayEmployee(employee)
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Status',
      dataIndex: 'isApproved',
      key: 'isApproved',
      sortOrder: 'descend',
      sorter: (a, b) => a.isApproved.localeCompare(b.isApproved),
      render(text, record) {
        return {
          props: {
            style: { background: record.isApproved === 'Pending' ? 'lightyellow' : 'white' }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: 'Action',
      key: 'action',
      render(text, employee) {
        return {
          children:
            employee.isApproved === 'Pending' ? (
              <>
                <Button
                  type="link"
                  onClick={e => {
                    e.preventDefault();
                    confirmApproval(employee.id);
                  }}
                >
                  Approve
                </Button>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={e => {
                    e.preventDefault();
                    confirmRejection(employee.id);
                  }}
                >
                  Reject
                </Button>
              </>
            ) : (
              <Button
                type="link"
                onClick={e => {
                  e.preventDefault();
                  confirmRejection(employee.id);
                }}
              >
                Remove
              </Button>
            )
        };
      }
    }
  ];

  const detailEmployees = () => (
    <>
      <Box px={[2, 3]}>
        <Row>
          <Col>
            <Table
              style={{ marginBottom: '2rem' }}
              rowKey={record => record.id}
              columns={employeesCol}
              dataSource={employees}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50']
              }}
              title={() => (
                <>
                  <div style={styles.tableTitleContainer}>
                    <Title style={styles.tableTitleText}>Employees</Title>
                  </div>
                </>
              )}
            />
          </Col>
        </Row>
      </Box>
    </>
  );

  const generalRecsCol = [
    {
      title: 'Location',
      dataIndex: 'location_name',
      key: 'location_name',
      width: 300
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      width: 675
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => joinedDisplay(text),
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix()
    },
    {
      title: 'Strengths',
      key: 'tags',
      dataIndex: 'tags',
      render: (text, { tags }) =>
        tags != undefined && tags.length > 0 ? (
          <>
            <span>
              {tags.map(tag => {
                return (
                  <Tag style={styles.propTag} key={tag.id}>
                    {tag.name.toUpperCase()}
                  </Tag>
                );
              })}
            </span>
          </>
        ) : (
          <span></span>
        )
    }
  ];

  const recsCol = [
    {
      title: 'Featured',
      key: 'featured',
      dataIndex: 'featured',
      sortOrder: 'descend',
      sorter: (a, b) => a.featured - b.featured,
      render: (text, rec) => (
        <>
          <Button
            style={styles.tableFeaturedBtn}
            onClick={e => {
              e.preventDefault();
              const id = rec.id;
              let updateRecs = recs;
              let recIndex = recs.findIndex(item => item.id === id);
              if (recIndex !== -1) {
                let updatedRec = recs[recIndex];
                updatedRec.featured = !updatedRec.featured;
                updateRecs.splice(recIndex, 1, updatedRec);
                setRefreshTrigger(`${rec.id}-${updatedRec.featured}`);
                setRecs(updateRecs);
              }
              putRequest(`/recommendations/${rec.id}/toggle_featured`);
            }}
          >
            <Icon
              type="heart"
              style={styles.tableFeaturedIcon}
              theme="twoTone"
              twoToneColor={rec.featured ? theme.colors.red : theme.colors.moonGray}
            />
          </Button>
        </>
      ),
      className: data.business.premium ? 'ant-table-column--show' : 'ant-table-column--hide',
      width: 150
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      key: 'recipient_id',
      render: (text, rec) => {
        return `${rec.Recipient.first_name} ${rec.Recipient.last_name}`;
      }
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note'
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => joinedDisplay(text),
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix()
    },
    {
      title: 'Strengths',
      key: 'tags',
      dataIndex: 'tags',
      render: (text, { tags }) =>
        tags != undefined && tags.length > 0 ? (
          <>
            <span>
              {tags.map(tag => {
                return (
                  <Tag style={styles.propTag} key={tag.id}>
                    {tag.name.toUpperCase()}
                  </Tag>
                );
              })}
            </span>
          </>
        ) : (
          <span></span>
        )
    }
  ];

  const detailRecs = () => (
    <>
      <Box px={[2, 3]}>
        <Row>
          <Col>
            {data.owner_id === '' || null ? (
              <Table
                style={{ marginBottom: '2rem' }}
                rowKey={record => record.id}
                columns={generalRecsCol}
                dataSource={generalRecs}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50']
                }}
                title={() => (
                  <>
                    <div style={styles.tableTitleContainer}>
                      <Title style={styles.tableTitleText}>General Props</Title>
                    </div>
                  </>
                )}
              />
            ) : (
              <>
                <Table
                  style={{ marginBottom: '2rem' }}
                  rowKey={record => record.id}
                  columns={recsCol}
                  dataSource={recs}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50']
                  }}
                  title={() => (
                    <>
                      <div style={styles.tableTitleContainer}>
                        <Title style={styles.tableTitleText}>Props</Title>
                      </div>
                    </>
                  )}
                />
                <Table
                  style={{ marginBottom: '2rem' }}
                  rowKey={record => record.id}
                  columns={generalRecsCol}
                  dataSource={generalRecs}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50']
                  }}
                  title={() => (
                    <>
                      <div style={styles.tableTitleContainer}>
                        <Title style={styles.tableTitleText}>General Props</Title>
                      </div>
                    </>
                  )}
                />
              </>
            )}
          </Col>
        </Row>
      </Box>
    </>
  );

  return (
    <Content
      style={{
        backgroundColor: 'white',
        width: '100%'
      }}
    >
      {detailHeader()}
      <Divider />
      {detailEmployees()}
      <Divider />
      {detailRecs()}
    </Content>
  );
};

export default LocationDetail;
