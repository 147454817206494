import React from 'react';
import Container from './Container';
import '../../App.css';
import AppHeader from '../Header';
import AppFooter from '../Footer';

const styles = {
  layout: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#11141e'
  }
};

const Layout = React.forwardRef(({ children }) => {
  return <Container style={styles.layout}>{children}</Container>;
});

Layout.displayName = 'Layout';

const Sidebar = React.forwardRef(({ children }) => {
  return (
    <>
      <AppHeader />
      <div className="main">{children}</div>
      <AppFooter />
    </>
  );
});

Sidebar.displayName = 'Sidebar';

const Full = React.forwardRef(({ children }) => {
  return (
    <>
      <AppHeader />
      <Layout>{children}</Layout>
      <AppFooter />
    </>
  );
});

Full.displayName = 'fullLayout';

const Layouts = {
  Layout,
  Sidebar,
  Full
};

export default Layouts;
