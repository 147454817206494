import styled from 'styled-components';
import { fontSize, fontWeight, textAlign, space, color } from 'styled-system';
import propTypes from 'prop-types';
import theme from './theme';

const Text = styled.p`
  ${fontSize}
  ${space}
  ${color}
  ${textAlign}
  ${fontWeight}
`;

Text.displayName = 'Text';

Text.propTypes = {
  ...propTypes.fontSize,
  ...propTypes.fontWeight,
  ...propTypes.textAlign,
  ...propTypes.space,
  ...propTypes.color
};

Text.defaultProps = {
  theme,
  m: 0,
  fontSize: '1rem',
  color: theme.colors.textColor
};

Text.span = Text.withComponent('span');
Text.p = Text.withComponent('p');
Text.s = Text.withComponent('s');

export default Text;
