import { Input } from 'antd';
import React from 'react';

const Search = Input.Search;

const TableSearch = ({ onSearch, ...props }) => (
  <div {...props}>
    <Search size="large" placeholder="Search" onSearch={onSearch} style={{ width: 200 }} />
  </div>
);

export default TableSearch;
