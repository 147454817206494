import { Button, Col, Divider, Icon, Layout, Row, Table } from 'antd';
import { Box, TableSearch, theme, Title, Loader, Text } from './common';
import React, { useContext, useState } from 'react';
import { createBrowserHistory } from 'history';
import useApi from '../hooks/useApi';
import { getRequest } from '../utils/network';
import { UserContext } from '../contexts/UserContext';
import LocationList from './LocationList';

const history = createBrowserHistory({
  forceRefresh: true
});

const pageSize = 50;
var moment = require('moment');

const { Content } = Layout;

const BusinessList = () => {
  const [pagination, setPagination] = useState({ current: 1, pageSize });
  const { data, isLoading } = useApi(`/businesses?per_page=${pageSize}`);
  const [dataSource, setDataSource] = useState(data);
  const [hasSearch, setHasSearch] = useState(false);
  const [user, setUser] = useContext(UserContext);

  if (isLoading) {
    return <Loader />;
  }

  const { businesses, count } = data;

  const changePage = (page, pageSize) => {
    getRequest(`/businesses?page=${page}&per_page=${pageSize}`).then(({ data }) => {
      setHasSearch(true);
      setDataSource(data.businesses);
      setPagination({ ...pagination, current: page });
    });
  };

  const handleChange = (p, filters, sorter) => {
    changePage(p.current, p.pageSize);
  };

  const handleSearch = searchText => {
    setHasSearch(true);

    const results = businesses.filter(({ name }) => {
      name = name.toLowerCase();
      return name.includes(searchText.toLowerCase());
    });

    setDataSource(results);
  };

  const joinedDisplay = time => `${moment(time).format('MMMM DD, YYYY')}`;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, business) =>
        user.role === 100 || user.id === business.owner_id || business.manager_id ? (
          <Button type="link" style={{ fontSize: '1rem' }} href={`/businesses/${business.id}`}>
            {text}
          </Button>
        ) : (
          <Text style={{ padding: '0 15px', letterSpacing: 0 }}>{text}</Text>
        )
    },
    {
      title: 'Premium',
      key: 'premium',
      dataIndex: 'premium',
      render: premium => (
        <Icon
          type="dollar"
          style={{ fontSize: '1.125rem' }}
          theme="twoTone"
          twoToneColor={premium ? theme.colors.green : theme.colors.moonGray}
        />
      ),
      sorter: (a, b) => a.premium - b.premium,
      sortDirections: ['descend', 'ascend'],
      width: 135,
      align: 'center',
      showHeader: false
    },
    {
      title: 'Locations',
      dataIndex: 'locations_count',
      key: 'locations_count',
      sorter: (a, b) => a.locations_count - b.locations_count
    },
    {
      title: 'Business Owner',
      key: 'owner',
      // dataIndex: 'owner.first_name',
      render: (_, record) => {
        if (record.owner != undefined) {
          return `${record.owner.first_name} ${record.owner.last_name}`;
        }
        return null;
      }
    },
    {
      title: 'Date Added',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => joinedDisplay(text),
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix()
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, business) => (
        <>
          {user.id === business.owner_id || user.role === 100 ? (
            <>
              <Button type="link" icon="eye" onClick={() => history.push(`/businesses/${business.id}`)} />
              <Divider type="vertical" />
            </>
          ) : null}
          {user.role === 100 || user.id === business.owner_id || business.manager_id ? (
            <>
              <Button type="link" icon="edit" onClick={() => history.push(`/businesses/${business.id}/edit`)} />
              <Divider type="vertical" />
            </>
          ) : null}
          <Button type="link" icon="mail" onClick={() => (window.location.href = `mailto:${business.contact_email}`)} />
        </>
      )
    }
  ];

  return (
    <Content
      style={{
        backgroundColor: 'white',
        width: '100%'
      }}
    >
      <Box px={[2, 3]}>
        <Row>
          <Col>
            <Table
              style={{ marginBottom: '2rem' }}
              columns={columns}
              dataSource={hasSearch ? dataSource : businesses}
              pagination={{
                ...pagination,
                total: count
              }}
              onChange={handleChange}
              rowKey={business => business.id}
              title={() => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-around',
                      padding: '0.5rem 0'
                    }}
                  >
                    <Title style={{ marginLeft: 0, marginBottom: 0, fontWeight: 400 }}>Business Accounts</Title>
                    {/* revisit this at a later date re: building */}
                    {/* an elastic search field for businesses and locations */}
                    {/* <TableSearch onSearch={handleSearch} /> */}
                    {user.role == 100 ? (
                      <Button
                        size="large"
                        style={{ float: 'right', margin: '0 1rem' }}
                        type="primary"
                        onClick={() => history.push('/add-business')}
                      >
                        Add Business
                      </Button>
                    ) : null}
                  </div>
                </>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <LocationList />
          </Col>
        </Row>
      </Box>
    </Content>
  );
};
export default BusinessList;
