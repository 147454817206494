import React from 'react';
import { Upload } from 'antd';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class HeroBgUpload extends React.Component {
  state = {
    loading: false
  };

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false
        })
      );
    }
  };

  render() {
    const heroBgContainer = {
      height: '35vh',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-end',
      marginBottom: '1.5rem'
    };

    const uploadButton = (
      <div styles={heroBgContainer}>
        <div className="ant-upload-text">Upload</div>
        <div className="ant-upload-text">Profile Background</div>
      </div>
    );
    const { imageUrl } = this.state;
    return (
      <Upload
        name="HeroBgUpload"
        listType="picture-card"
        className="HeroBgUpload-uploader"
        showUploadList={false}
        action=""
        beforeUpload={() => false}
        onChange={this.handleChange}
      >
        {imageUrl ? <img src={imageUrl} alt="HeroBgUpload" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    );
  }
}

export default HeroBgUpload;
