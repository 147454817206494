import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { Logo, theme } from './common';
import { UserContext } from '../contexts/UserContext';
import { delStoredToken } from '../utils/auth';

const AppHeader = () => {
  const [user, setUser] = useContext(UserContext);

  const logout = e => {
    e.preventDefault();

    console.log('logout');
    delStoredToken();
    setUser({
      loggedIn: false,
      userData: {},
      username: '',
      role: ''
    });
  };

  const styles = {
    header: {
      backgroundColor: '#11141e',
      padding: '1.5rem',
      height: 90,
      fontSize: 16
    },
    link: {
      color: theme.colors.white,
      fontWeight: 600,
      lineHeight: '40px'
    }
  };

  return (
    <header>
      <Row type="flex" align="middle" justify="space-between" style={styles.header}>
        <Col>
          <Logo height="24px" lineHeight="48px" alt="Props Logo" />
        </Col>
        <Row type="flex" gutter={32}>
          {user.loggedIn ? (
            <>
              <Col>
                <Link style={styles.link} to="/">
                  Home
                </Link>
              </Col>
              {user.role == 100 ? (
                <>
                  <Col>
                    <Link style={styles.link} to="/add-business">
                      Add Business
                    </Link>
                  </Col>
                  <Col>
                    <Link style={styles.link} to="/add-location">
                      Add Location
                    </Link>
                  </Col>
                </>
              ) : null}
              <Col>
                <Button size="large" style={{ fontWeight: 600 }} onClick={logout}>
                  Logout
                </Button>
              </Col>
            </>
          ) : (
            <>
              <Col>
                <Link style={styles.link} to="/login">
                  Log In
                </Link>
              </Col>
            </>
          )}
        </Row>
      </Row>
    </header>
  );
};

export default AppHeader;
