import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import LocationForm from './LocationForm';
import { getRequest, putRequest } from '../utils/network';
import { Title, Loader } from './common';
import { Alert, Layout } from 'antd';
import { notificationRedirect } from '../utils/helpers';

const history = createBrowserHistory({
  forceRefresh: true
});

const { Content } = Layout;

const EditLocation = props => {
  const { match } = props;
  let { locationId } = match.params;
  const [isLoading, setLoading] = useState(true);
  const [location, setLocation] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
    getRequest(`/locations/${locationId}`)
      .then(({ data }) => {
        setLocation(data);
        setLoading(false);
      })
      .catch(() => {
        setError('There was an error loading the organization. Reload the page to try loading again.');
      });
  }, [locationId]);

  const handleSubmit = data => {
    putRequest(`/locations/${locationId}`, data).then(response => {
      try {
        notificationRedirect('/locations', locationId);
      } catch (error) {
        console.log(error);
        setError('There was an error saving your location. Please check the values and try again.');
      }
    });
  };

  return (
    <>
      <Content
        style={{
          minWidth: 650,
          margin: '0 auto',
          padding: '2rem 0',
          justifyContent: 'start',
          backgroundColor: 'white',
          width: '100%'
        }}
      >
        <Title style={{ textAlign: 'center' }}>Edit Location</Title>
        {error === '' ? null : <Alert message={error} type="error" showIcon />}
        {isLoading ? <Loader /> : <LocationForm {...props} handleSubmit={handleSubmit} locationData={location} />}
      </Content>
    </>
  );
};

export default EditLocation;
