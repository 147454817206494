import React from 'react';
import styled from 'styled-components';
import {
  color,
  space,
  minWidth,
  borders,
  boxShadow,
  fontFamily,
  fontWeight,
  buttonStyle,
  variant,
  width
} from 'styled-system';
import { ButtonBody } from './Button';
import SolidButton, { StyledSolidButton } from './SolidButton';
import theme from '../theme';

const buttonSize = variant({
  prop: 'size',
  key: 'buttonSizes'
});

// outline style
const StyledOutlineButton = styled(StyledSolidButton)`
  ${color}
  ${space}
  ${minWidth}
  ${borders}
  ${boxShadow}
  ${fontFamily}
  ${fontWeight}
  ${buttonStyle}
  ${buttonSize}
  ${width}
  & {
    color: ${props => props.color};
    background: transparent;
    border-width: 0.175rem;
    border-style: solid;
    height: 2.5rem;
    line-height: 0;
  }
  &:hover {
    border-color: ${props => props.color};
  }
  &:hover::before {
    opacity: 0;
  }
  &:active {
    background: #fff;
  }
  &:active::before {
    opacity: 0.1;
  }
  &::before {
    opacity: 0;
  }
`;

const OutlineButton = React.forwardRef(({ children, icon, iconpos, ...props }, ref) => {
  return (
    <StyledOutlineButton {...props} ref={ref}>
      <ButtonBody icon={icon} iconpos={iconpos}>
        {children}
      </ButtonBody>
    </StyledOutlineButton>
  );
});

OutlineButton.defaultProps = {
  ...SolidButton.defaultProps,
  theme,
  as: 'button',
  size: 'medium',
  m: 0,
  px: 2,
  py: 0,
  fontFamily: theme.fonts.sansSerifAlt,
  fontWeight: 600,
  variant: 'primary',
  width: '100%',
  border: 2,
  borderColor: theme.colors.primaryDark,
  color: theme.colors.primaryDark,
  boxShadow: 0
};

export default OutlineButton;
