import React, { useState } from 'react';
import { createBrowserHistory } from 'history';
import BusinessForm from './BusinessForm';
import { postRequest } from '../utils/network';
import { Title } from './common';
import { Alert, Layout } from 'antd';
import { notificationRedirect } from '../utils/helpers';

const history = createBrowserHistory({
  forceRefresh: true
});

const { Content } = Layout;

const AddBusiness = props => {
  const [error, setError] = useState('');

  const handleSubmit = data => {
    setError('');
    postRequest(`/businesses`, data).then(response => {
      const businessId = response.data.id;
      try {
        notificationRedirect('/businesses', businessId);
      } catch (error) {
        console.log(error);
        setError('There was an error adding your business. Please check the values and try again.');
      }
    });
  };

  return (
    <>
      <Content
        style={{
          minWidth: 650,
          margin: '0 auto',
          padding: '2rem 0',
          justifyContent: 'start',
          backgroundColor: 'white',
          width: '100%'
        }}
      >
        <Title style={{ textAlign: 'center' }}>Add Business</Title>
        {error === '' ? null : <Alert message={error} type="error" showIcon />}
        <BusinessForm {...props} handleSubmit={handleSubmit} />
      </Content>
    </>
  );
};

export default AddBusiness;
