import styled from 'styled-components';
import { color } from 'styled-system';
import { lighten } from 'polished';
import theme from './theme';

const Link = styled.a`
  ${color} & {
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    line-height: 1.4;
    text-decoration-color: ${theme.colors.primaryDark};
    text-decoration-skip-ink: auto;
    &:hover {
      opacity: 1;
      color: ${props => lighten(0.25, theme.colors.primaryDark)};
      text-decoration-color: ${props => lighten(0.25, theme.colors.primaryDark)};
    }
  }
`;

Link.displayName = 'Link';

Link.propTypes = {
  ...color.propTypes,
};

Link.defaultProps = {
  theme,
};

export default Link;
