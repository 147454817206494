import styled from 'styled-components';
import { themeGet, space, color, backgroundColor } from 'styled-system';
import theme from './theme';

const Badge = styled.div`
  border-radius: 99999px;
  line-height: 1;
  vertical-align: middle;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: ${themeGet('letterSpacings.caps')} ${backgroundColor};
  ${space} ${color};
`;

Badge.displayName = 'Badge';

Badge.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
};

Badge.defaultProps = {
  theme,
  px: 2,
  py: 1,
  color: theme.colors.white,
  backgroundColor: theme.colors.blue,
};

export default Badge;
