import './App.css';

import React, { useContext } from 'react';
import BusinessList from './components/BusinessList';
import styled, { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { Layout, theme } from './components/common';
import './App.css';
import AddBusiness from './components/AddBusiness';
import EditBusiness from './components/EditBusiness';
import BusinessDetail from './components/BusinessDetail';
import EditLocation from './components/EditLocation';
import AddLocation from './components/AddLocation';
import LocationDetail from './components/LocationDetail';
import Login from './components/Login';
import { UserProvider, UserContext } from './contexts/UserContext';

const AppContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
`;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [user] = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={props =>
        user.loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

const App = props => (
  <ThemeProvider theme={{ theme }}>
    <UserProvider>
      <Router>
        <AppContainer>
          <Layout.Full>
            <PrivateRoute exact path="/" component={BusinessList} />
            <PrivateRoute exact path="/businesses/:businessId" component={BusinessDetail} />
            <PrivateRoute exact path="/add-business" component={AddBusiness} />
            <PrivateRoute exact path="/businesses/:businessId/edit" component={EditBusiness} />
            <PrivateRoute exact path="/locations/:locationId/" component={LocationDetail} />
            <PrivateRoute exact path="/add-location" component={AddLocation} />
            <PrivateRoute exact path="/businesses/:businessId/add" component={AddLocation} />
            <PrivateRoute exact path="/locations/:locationId/edit" component={EditLocation} />
            <Route path="/login" component={Login} />
          </Layout.Full>
        </AppContainer>
      </Router>
    </UserProvider>
  </ThemeProvider>
);

export default hot(App);
