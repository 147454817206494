import React, { PureComponent } from 'react';
import { createBrowserHistory } from 'history';
import { businessTypes, states, BASE_URL, NULL_UUID } from '../utils/constants';
import { Row, Col, Button, Form, Input, Select, Spin, Card, Upload, Icon } from 'antd';
import { Box, Container, Subtitle } from './common';
import { getRequest, postRequest, putRequest } from '../utils/network';
import { getStoredToken } from '../utils/auth';
import { UserContext } from '../contexts/UserContext';

const history = createBrowserHistory({
  forceRefresh: true
});

const FormItem = Form.Item;
const { Option } = Select;

class LocationForm extends PureComponent {
  static contextType = UserContext;
  getImageURL = field => {
    if (this.props.locationData == undefined) {
      return '';
    }
    return this.props.locationData[field];
  };

  state = {
    newTags: [],
    inputVisible: false,
    inputValue: '',
    // UserSearchSelect
    data: [],
    value: [],
    fetching: false,
    primary_url: this.getImageURL('image_url'),
    secondary_url: this.getImageURL('secondary_image_url')
  };

  // UserSearchSelect
  fetchUser = value => {
    console.log('fetching user', value);
    this.lastFetchId += 1;
    this.setState({ data: [], fetching: true });
    getRequest(`/search?q=${value}&t=users`).then(response => {
      const data = response.data.map(user => ({
        text: `${user.name}`,
        value: `${user.id}`
      }));
      this.setState({ data, fetching: false });
    });
  };

  handleSearch = value => {
    this.setState({
      value,
      data: [],
      fetching: false
    });
    console.log(value);
  };

  fetchBusiness = value => {
    console.log('fetching business', value);
    this.lastFetchId += 1;
    this.setState({ data: [], fetching: true });
    getRequest(`/search?q=${value}&t=businesses`).then(response => {
      const data = response.data.map(d => ({
        text: `${d.name}`,
        subtitle: `${d.subtitle}`,
        value: `${d.id}`
      }));
      this.setState({ data, fetching: false });
    });
  };

  handleBusinessSearch = value => {
    this.setState({
      value,
      data: [],
      fetching: false
    });
    console.log(value);
  };
  // end UserSearchSelect

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  saveInputRef = input => {
    this.input = input;
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { state } = this;
    const { inputValue } = state;
    let { newTags } = state;
    if (inputValue && newTags.filter(tag => tag.label === inputValue).length === 0) {
      newTags = [...newTags, { key: `new-${newTags.length}`, label: inputValue }];
    }
    this.setState({
      newTags,
      inputVisible: false,
      inputValue: ''
    });
  };

  onSubmit = e => {
    const {
      form,
      handleSubmit,
      match: { params }
    } = this.props;
    const { primary_url: image_url, secondary_url: secondary_image_url } = this.state;

    console.log(params);

    console.log();
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let manager_id;
        if (values.manager_id != undefined) {
          manager_id = values.manager_id.key;
        }
        let business_id;
        if (params.businessId != undefined) {
          business_id = params.businessId;
        } else if (values.business_id != undefined) {
          business_id = values.business_id.key;
        } else {
          business_id = NULL_UUID;
        }
        console.log(business_id);
        const location = {
          name: values.name,
          type: values.type,
          business_id: business_id,
          manager_id: manager_id,
          email: values.email,
          phone: values.phone,
          address: {
            address_1: values.address_1,
            address_2: values.address_2,
            city: values.city,
            state: values.state,
            postal_code: values.postal_code
          },
          image_url,
          secondary_image_url
        };
        handleSubmit(location);
        console.log(location);
      }
    });
  };

  stateSelectOptions = () =>
    Object.keys(states).map(k => (
      <Select.Option key={k} value={k}>
        {states[k]}
      </Select.Option>
    ));

  typeSelectOptions = () =>
    Object.keys(businessTypes).map(k => (
      <Select.Option key={k} value={k}>
        {businessTypes[k]}
      </Select.Option>
    ));

  render = () => {
    const {
      form: { getFieldDecorator, validateFields }
    } = this.props;
    const { locationData = { address: {} }, user_role } = this.props;
    const address = locationData.address;
    const stateOptions = this.stateSelectOptions();
    const typeOptions = this.typeSelectOptions();
    // UserSearchSelect
    const { fetching, data, value, primary_url, secondary_url } = this.state;

    const displayManager = manager => `${manager.first_name} ${manager.last_name}`;
    const getManagerId = manager => {
      if (locationData == undefined || locationData.manager == null) {
        return '';
      }
      return locationData.manager.id;
    };

    const displayBusiness = business => `${business.name}`;
    const getBusinessId = business => {
      if (locationData == undefined || locationData.business == null) {
        return '';
      }
      return locationData.business.id;
    };

    const buildFormInput = (name, source, placeholder) => (
      <FormItem className="form__item-label" hasFeedback>
        {getFieldDecorator(name, {
          initialValue: source[name]
        })(<Input size="large" type="text" name={name} placeholder={placeholder} />)}
      </FormItem>
    );

    const buildFormSelect = (name, source, options, placeholder) => (
      <FormItem hasFeedback>
        {getFieldDecorator(name, {
          initialValue: source[name],
          rules: [
            name === 'type'
              ? {
                  validator(rule, value, callback) {
                    if (!value) {
                      callback('Type is required');
                    }

                    callback();
                  }
                }
              : ''
          ]
        })(
          <Select size="large" name={name} placeholder={placeholder}>
            {options}
          </Select>
        )}
      </FormItem>
    );

    const { match } = this.props;
    const uploadProps = {
      action: `${BASE_URL}upload_image/location`,
      headers: {
        Authorization: getStoredToken() || ''
      },
      showUploadList: false,
      onChange(info) {
        console.log(info);
      },
      onSuccess(file, response) {
        console.log('Success!!');
        console.log(file);
        console.log(response);
      },
      onError(err) {
        console.log('onError', err);
      },
      customRequest: ({ action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials }) => {
        const formData = new FormData();

        formData.append('image', file);

        postRequest(action, formData)
          .then(resp => {
            if (filename === 'primary_image') {
              this.setState({ primary_url: resp.data.url });
            } else if (filename === 'secondary_image') {
              this.setState({ secondary_url: resp.data.url });
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    };

    const resetImage = name => {
      if (name === 'primary_image') {
        this.setState({ primary_url: '' });
      }
      if (name === 'secondary_image') {
        this.setState({ secondary_url: '' });
      }
    };

    const deleteImage = name => {
      putRequest(`${BASE_URL}locations/${match.params.locationId}/remove_image`, { image_field: name })
        .then(({ data }) =>
          name === 'primary_image'
            ? this.setState({ primary_url: '' })
            : name === 'secondary_image'
            ? this.setState({ secondary_url: '' })
            : this.setState({ primary_url: data.image_url, secondary_url: data.secondary_image_url })
        )
        .catch(resp => console.log(resp));
    };

    return (
      <>
        <Container maxWidth={48} px={[2, 3, 4]} flexDirection="column" flexAlign="start">
          <Box px={[2, 3]} mb={[3, 4]}>
            <Row style={{ marginBottom: '3.5rem' }} type="flex" justify="center">
              <Col span={24}>
                <Form className="form" onSubmit={this.onSubmit} layout="horizontal">
                  <FormItem>
                    <Col span={16}>{buildFormInput('name', locationData, 'Location Name')}</Col>
                    <Col span={7} offset={1}>
                      {buildFormSelect('type', locationData, typeOptions, 'Location Type')}
                    </Col>
                  </FormItem>
                  <FormItem>
                    <Col span={11}>
                      <FormItem>
                        <Card
                          cover={<img src={primary_url} />}
                          actions={[
                            <Upload name="primary_image" {...uploadProps}>
                              <Button>
                                <Icon type="upload" />
                                Upload
                              </Button>
                            </Upload>,
                            <Button
                              name="primary_image"
                              onClick={e =>
                                match.params.locationId ? deleteImage('primary_image') : resetImage('primary_image')
                              }
                            >
                              <Icon type="delete" />
                              Delete
                            </Button>
                          ]}
                        >
                          Banner Image
                        </Card>
                      </FormItem>
                    </Col>
                    <Col span={11} offset={2}>
                      <FormItem>
                        <Card
                          cover={<img src={secondary_url} />}
                          actions={[
                            <Upload name="secondary_image" {...uploadProps}>
                              <Button>
                                <Icon type="upload" />
                                Upload
                              </Button>
                            </Upload>,
                            <Button
                              name="secondary_image"
                              onClick={e =>
                                match.params.locationId ? deleteImage('secondary_image') : resetImage('secondary_image')
                              }
                            >
                              <Icon type="delete" />
                              Delete
                            </Button>
                          ]}
                        >
                          Profile Image
                        </Card>
                      </FormItem>
                    </Col>
                  </FormItem>
                  {this.context[0].role == 100 ? (
                    <>
                      <Subtitle>Business</Subtitle>
                      <Form.Item>
                        {getFieldDecorator('business_id', {
                          initialValue:
                            locationData !== undefined &&
                            locationData['business_id'] === getBusinessId() &&
                            locationData['business_id'] !== NULL_UUID
                              ? { key: locationData['business_id'], label: displayBusiness(locationData.business) }
                              : undefined
                        })(
                          <Select
                            showSearch
                            labelInValue
                            size="large"
                            placeholder="Select Business"
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={this.fetchBusiness}
                            onChange={this.handleBusinessSearch}
                            style={{ width: '100%' }}
                            allowClear
                          >
                            {data.map(d => (
                              <Option key={d.value}>
                                {d.text} &nbsp;&ndash;&nbsp;
                                <small>{d.subtitle}</small>
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </>
                  ) : null}
                  <Subtitle>Location Manager</Subtitle>
                  <Form.Item>
                    {getFieldDecorator('manager_id', {
                      initialValue:
                        locationData !== undefined && locationData['manager_id'] === getManagerId()
                          ? { key: locationData['manager_id'], label: displayManager(locationData.manager) }
                          : undefined
                    })(
                      <Select
                        showSearch
                        labelInValue
                        size="large"
                        placeholder="Select Location Manager"
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        filterOption={false}
                        onSearch={this.fetchUser}
                        onChange={this.handleSearch}
                        style={{ width: '100%' }}
                        allowClear
                      >
                        {data.map(d => (
                          <Option key={d.value}>{d.text}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Subtitle>Location Details</Subtitle>
                  {buildFormInput('email', locationData, 'Location Email')}
                  {buildFormInput('phone', locationData, 'Location Phone')}
                  {buildFormInput('address_1', address, 'Street Address')}
                  {buildFormInput('address_2', address, 'Unit Number')}
                  <Form.Item>
                    <Col span={10}>{buildFormInput('city', address, 'City')}</Col>
                    <Col span={7} offset={1}>
                      {buildFormSelect('state', address, stateOptions, 'State')}
                    </Col>
                    <Col span={5} offset={1}>
                      {buildFormInput('postal_code', address, 'ZIP Code')}
                    </Col>
                  </Form.Item>
                  <Button size="large" type="primary" htmlType="submit" style={{ marginTop: '1rem' }}>
                    Save
                  </Button>
                </Form>
              </Col>
            </Row>
          </Box>
        </Container>
      </>
    );
  };
}

export default Form.create()(LocationForm);
