import styled from 'styled-components';
import { color, fontFamily, fontWeight, space, maxWidth } from 'styled-system';
import theme from './theme';

const Heading = styled.h1`
    ${color}
    ${space}
    ${fontWeight}
    ${fontFamily}
    ${maxWidth}

    & {
      font-size: calc(1.25rem + (6-2) * (100vw - 400px)/(900-400));
      letter-spacing: -0.0025rem;
      line-height: 1;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
`;

Heading.defaultProps = {
  theme,
  fontWeight: 600,
  fontFamily: theme.fonts.sansSerif,
  mx: 'auto'
};

Heading.displayName = 'Heading';

export default Heading;
