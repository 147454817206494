// export const BASE_URL = 'http://127.0.0.1:3000/';
export const BASE_URL = 'https://give.propslove.com/';
// include Props Staging URL
// export const BASE_URL = 'https://getprops.turbomobs.com/';
export const TOKEN = 'access-token';

export const NULL_UUID = '00000000-0000-0000-0000-000000000000';

export const states = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District Of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming'
};

export const businessTypes = {
  'ambulance service': 'Ambulance Service',
  automotive: 'Automotive',
  bar: 'Bar',
  'coffee shop': 'Coffee Shop',
  consulting: 'Consulting',
  education: 'Education',
  entertainment: 'Entertainment',
  finance: 'Finance',
  'fire departments': 'Fire Departments',
  fitness: 'Fitness',
  government: 'Government',
  'health care': 'Health Care',
  hospitality: 'Hospitality',
  legal: 'Legal',
  media: 'Media',
  nightlife: 'Nightlife',
  'non profit': 'Non Profit',
  other: 'Other',
  'police departments': 'Police Departments',
  'real estate': 'Real Estate',
  restaurant: 'Restaurant',
  shopping: 'Shopping',
  sports: 'Sports',
  travel: 'Travel'
};

export const roleValues = {
  admin: 100,
  owner: 50,
  manager: 30,
  user: 1
};
