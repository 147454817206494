import styled from 'styled-components';
import { space, height, width, maxWidth, color, fontSize, textAlign, display } from 'styled-system';

const Box = styled.div`
  ${color}
  ${fontSize}
  ${textAlign}
  ${space}
  ${width}
  ${height}
  ${maxWidth}
  ${display}

`;

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...maxWidth.propTypes,
  ...display.propTypes
};

Box.defaultProps = {
  width: '100%'
};

Box.displayName = 'Box';

export default Box;
