import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import BusinessForm from './BusinessForm';
import { getRequest, putRequest } from '../utils/network';
import { Title, Loader } from './common';
import { Alert, Layout } from 'antd';
import { notificationRedirect } from '../utils/helpers';

const history = createBrowserHistory({
  forceRefresh: true
});

const { Content } = Layout;

const EditBusiness = props => {
  const { match } = props;
  let { businessId } = match.params;
  const [isLoading, setLoading] = useState(true);
  const [business, setBusiness] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
    getRequest(`/businesses/${businessId}`)
      .then(({ data }) => {
        setBusiness(data);
        setLoading(false);
      })
      .catch(() => {
        setError('There was an error loading the organization. Reload the page to try loading again.');
      });
  }, [businessId]);

  const handleSubmit = data => {
    putRequest(`/businesses/${businessId}`, data).then(response => {
      try {
        notificationRedirect('/businesses', businessId);
      } catch (error) {
        console.log(error);
        setError('There was an error saving your business. Please check the values and try again.');
      }
    });
    console.log(business);
  };

  return (
    <>
      <Content
        style={{
          minWidth: 650,
          margin: '0 auto',
          padding: '2rem 0',
          justifyContent: 'start',
          backgroundColor: 'white',
          width: '100%'
        }}
      >
        <Title style={{ textAlign: 'center' }}>Edit Business</Title>
        {error === '' ? null : <Alert message={error} type="error" showIcon />}
        {isLoading ? <Loader /> : <BusinessForm {...props} handleSubmit={handleSubmit} businessData={business} />}
      </Content>
    </>
  );
};

export default EditBusiness;
