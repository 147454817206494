import axios from 'axios';
import { BASE_URL } from './constants';
import { getStoredToken } from './auth';

const request = ({ method = 'get', url, data = {}, params = {} }) => {
  const authHeader = getStoredToken() || '';
  return axios({
    baseURL: BASE_URL,
    method,
    url,
    data,
    params,
    headers: { Authorization: authHeader }
  });
};

export const getRequest = (url, params = {}) => request({ method: 'get', url, params });

export const postRequest = (url, data, params = {}) => request({ method: 'post', url, data, params });

export const putRequest = (url, data, params = {}) => request({ method: 'put', url, data, params });

export const deleteRequest = (url, params = {}) => request({ method: 'delete', url, params });
