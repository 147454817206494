import React, { PureComponent } from 'react';
import { createBrowserHistory } from 'history';
import { states, BASE_URL } from '../utils/constants';
import { Row, Col, Button, Form, Input, Select, Radio, Spin, Upload, Icon, Card } from 'antd';
import { Box, Container, Subtitle } from './common';
import { getRequest, postRequest, putRequest } from '../utils/network';
import { getStoredToken } from '../utils/auth';
import { UserContext } from '../contexts/UserContext';

const history = createBrowserHistory({
  forceRefresh: true
});

const FormItem = Form.Item;
const { Option } = Select;

class BusinessForm extends PureComponent {
  static contextType = UserContext;
  getImageURL = field => {
    if (this.props.businessData == undefined) {
      return '';
    }
    return this.props.businessData[field];
  };

  getPremium = premium => {
    if (this.props.businessData == undefined) {
      return '';
    }
    return this.props.businessData['premium'];
  };

  state = {
    newTags: [],
    inputVisible: false,
    inputValue: '',
    premium: this.getPremium(),
    // UserSearchSelect
    data: [],
    value: [],
    fetching: false,
    primary_url: this.getImageURL('image_url'),
    secondary_url: this.getImageURL('secondary_image_url')
  };

  // UserSearchSelect
  fetchUser = value => {
    console.log('fetching user', value);
    this.setState({ data: [], fetching: true });
    getRequest(`/search?q=${value}&t=users`).then(response => {
      const data = response.data.map(user => ({
        text: `${user.name}`,
        value: `${user.id}`
      }));
      this.setState({ data, fetching: false });
    });
  };

  handleSearch = value => {
    this.setState({
      value,
      data: [],
      fetching: false
    });
    console.log(value);
  };
  // end UserSearchSelect

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  saveInputRef = input => {
    this.input = input;
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { state } = this;
    const { inputValue } = state;
    let { newTags } = state;
    if (inputValue && newTags.filter(tag => tag.label === inputValue).length === 0) {
      newTags = [...newTags, { key: `new-${newTags.length}`, label: inputValue }];
    }
    this.setState({
      newTags,
      inputVisible: false,
      inputValue: ''
    });
  };

  togglePremium = e => {
    this.setState({ premium: !this.state.premium });
  };

  sanitizeURL = url => {
    if (url == undefined || url.length === 0) {
      return url;
    }

    const splitURL = url.split('://'); // split on protocol, if there
    const urlTail = splitURL[splitURL.length - 1];
    let protocol = 'http://';
    if (splitURL.length > 1) {
      protocol = splitURL[0] + '://';
    }
    return protocol + urlTail;
  };

  onSubmit = e => {
    const { form, handleSubmit } = this.props;
    const { primary_url: image_url, secondary_url: secondary_image_url } = this.state;

    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let owner_id;
        if (values.owner_id != undefined) {
          owner_id = values.owner_id.key;
        }
        const business = {
          owner_id: owner_id,
          name: values.name,
          contact_email: values.contact_email,
          contact_phone: values.contact_phone,
          premium: values.premium,
          website_url: this.sanitizeURL(values.website_url),
          facebook_url: this.sanitizeURL(values.facebook_url),
          instagram_url: this.sanitizeURL(values.instagram_url),
          twitter_url: this.sanitizeURL(values.twitter_url),
          address: {
            address_1: values.address_1,
            address_2: values.address_2,
            city: values.city,
            state: values.state,
            postal_code: values.postal_code
          },
          secondary_image_url,
          image_url
        };
        handleSubmit(business);
        console.log(business);
      }
    });
  };

  stateSelectOptions = () =>
    Object.keys(states).map(k => (
      <Select.Option key={k} value={k}>
        {states[k]}
      </Select.Option>
    ));

  render = () => {
    const {
      form: { getFieldDecorator, validateFields }
    } = this.props;
    const { businessData = { address: {} } } = this.props;
    const address = businessData.address;
    const stateOptions = this.stateSelectOptions();
    // UserSearchSelect
    const { fetching, data, value, primary_url, secondary_url } = this.state;

    const displayOwner = owner => `${owner.first_name} ${owner.last_name}`;
    const getOwnerId = owner => {
      if (businessData == undefined || businessData.owner == null) {
        return '';
      }
      return businessData.owner.id;
    };

    const buildFormInput = (name, source, placeholder) => (
      <FormItem hasFeedback>
        {getFieldDecorator(name, {
          initialValue: source[name]
        })(<Input size="large" type="text" name={name} placeholder={placeholder} />)}
      </FormItem>
    );

    const buildFormSelect = (name, source, options, placeholder) => (
      <FormItem hasFeedback>
        {getFieldDecorator(name, {
          initialValue: source[name]
        })(
          <Select size="large" name={name} placeholder={placeholder}>
            {options}
          </Select>
        )}
      </FormItem>
    );

    const { match } = this.props;
    const uploadProps = {
      action: `${BASE_URL}upload_image/business`,
      headers: {
        Authorization: getStoredToken() || ''
      },
      showUploadList: false,
      onChange(info) {
        console.log(info);
      },
      onSuccess(file, response) {
        console.log('Success!!');
        console.log(file);
        console.log(response);
      },
      onError(err) {
        console.log('onError', err);
      },
      customRequest: ({ action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials }) => {
        const formData = new FormData();

        formData.append('image', file);

        postRequest(action, formData)
          .then(resp => {
            console.log(resp.data);
            if (filename === 'primary_image') {
              this.setState({ primary_url: resp.data.url });
            } else if (filename === 'secondary_image') {
              this.setState({ secondary_url: resp.data.url });
            }
            console.log(filename);
          })
          .catch(err => {
            console.log(err);
          });
      }
    };

    const resetImage = name => {
      if (name === 'primary_image') {
        this.setState({ primary_url: '' });
      }
      if (name === 'secondary_image') {
        this.setState({ secondary_url: '' });
      }
    };

    const deleteImage = name => {
      putRequest(`${BASE_URL}businesses/${match.params.businessId}/remove_image`, { image_field: name })
        .then(({ data }) =>
          name === 'primary_image'
            ? this.setState({ primary_url: '' })
            : name === 'secondary_image'
            ? this.setState({ secondary_url: '' })
            : this.setState({ primary_url: data.image_url, secondary_url: data.secondary_image_url })
        )
        .catch(resp => console.log(resp));
    };

    return (
      <>
        <Container maxWidth={48} px={[2, 3, 4]} flexDirection="column" flexAlign="start">
          <Box px={[2, 3]} mb={[3, 4]}>
            <Form className="form" onSubmit={this.onSubmit} layout="horizontal">
              <FormItem>
                <Col span={16}>{buildFormInput('name', businessData, 'Business Name')}</Col>
                {this.context[0].role == 100 ? (
                  <Col span={7} offset={1}>
                    <FormItem>
                      {getFieldDecorator('premium', {
                        initialValue: businessData['premium'] === undefined ? false : businessData['premium']
                      })(
                        <Radio.Group size="large" name="premium" buttonStyle="solid" onChange={this.togglePremium}>
                          <Radio.Button value={false}>Basic</Radio.Button>
                          <Radio.Button value={true}>Premium</Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                ) : null}
              </FormItem>
              <FormItem>
                <Col span={11}>
                  <FormItem>
                    <Card
                      cover={<img src={primary_url} />}
                      actions={[
                        <Upload name="primary_image" {...uploadProps}>
                          <Button>
                            <Icon type="upload" />
                            Upload
                          </Button>
                        </Upload>,
                        <Button
                          name="primary_image"
                          onClick={e =>
                            match.params.businessId ? deleteImage('primary_image') : resetImage('primary_image')
                          }
                        >
                          <Icon type="delete" />
                          Delete
                        </Button>
                      ]}
                    >
                      Banner Image
                    </Card>
                  </FormItem>
                </Col>
                <Col span={11} offset={2}>
                  <FormItem>
                    <Card
                      cover={<img src={secondary_url} />}
                      actions={[
                        <Upload name="secondary_image" {...uploadProps}>
                          <Button>
                            <Icon type="upload" />
                            Upload
                          </Button>
                        </Upload>,
                        <Button
                          name="secondary_image"
                          onClick={e =>
                            match.params.businessId ? deleteImage('secondary_image') : resetImage('secondary_image')
                          }
                        >
                          <Icon type="delete" />
                          Delete
                        </Button>
                      ]}
                    >
                      Profile Image
                    </Card>
                  </FormItem>
                </Col>
              </FormItem>
              <Subtitle>Business Owner</Subtitle>
              <Form.Item>
                {getFieldDecorator('owner_id', {
                  initialValue:
                    businessData != undefined && businessData['owner_id'] == getOwnerId()
                      ? { key: businessData['owner_id'], label: displayOwner(businessData.owner) }
                      : undefined
                })(
                  <Select
                    showSearch
                    labelInValue
                    size="large"
                    placeholder="Select User"
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSearch={this.fetchUser}
                    onChange={this.handleSearch}
                    style={{ width: '100%' }}
                  >
                    {data.map(d => (
                      <Option key={d.value} label={d.text}>
                        {d.text}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Subtitle>Business Details</Subtitle>
              {buildFormInput('contact_email', businessData, 'Business Email')}
              {buildFormInput('contact_phone', businessData, 'Business Phone')}
              {buildFormInput('address_1', address, 'Street Address')}
              {buildFormInput('address_2', address, 'Unit Number')}
              <Form.Item>
                <Col span={10}>{buildFormInput('city', address, 'City')}</Col>
                <Col span={7} offset={1}>
                  {buildFormSelect('state', address, stateOptions, 'State')}
                </Col>
                <Col span={5} offset={1}>
                  {buildFormInput('postal_code', address, 'ZIP Code')}
                </Col>
              </Form.Item>
              {this.state.premium ? (
                <>
                  <Subtitle>Social Media</Subtitle>
                  <FormItem hasFeedback>
                    {getFieldDecorator('website_url', {
                      initialValue: businessData['website_url'],
                      rules: [
                        {
                          type: 'url',
                          transform: value => this.sanitizeURL(value),
                          message: 'This field must be a valid url.'
                        }
                      ]
                    })(
                      <Input
                        size="large"
                        // type="url"
                        name="website_url"
                        placeholder="Website Profile URL"
                      />
                    )}
                  </FormItem>
                  <FormItem hasFeedback>
                    {getFieldDecorator('facebook_url', {
                      initialValue: businessData['facebook_url'],
                      rules: [
                        {
                          type: 'url',
                          transform: value => this.sanitizeURL(value),
                          message: 'This field must be a valid url.'
                        }
                      ]
                    })(<Input size="large" name="facebook_url" placeholder="Facebook Profile URL" />)}
                  </FormItem>
                  <FormItem hasFeedback>
                    {getFieldDecorator('twitter_url', {
                      initialValue: businessData['twitter_url'],
                      rules: [
                        {
                          type: 'url',
                          transform: value => this.sanitizeURL(value),
                          message: 'This field must be a valid url.'
                        }
                      ]
                    })(<Input size="large" name="twitter_url" placeholder="Twitter Profile URL" />)}
                  </FormItem>
                  <FormItem hasFeedback>
                    {getFieldDecorator('instagram_url', {
                      initialValue: businessData['instagram_url'],
                      rules: [
                        {
                          type: 'url',
                          transform: value => this.sanitizeURL(value),
                          message: 'This field must be a valid url.'
                        }
                      ]
                    })(<Input size="large" name="instagram_url" placeholder="Instagram Profile URL" />)}
                  </FormItem>
                </>
              ) : null}
              <Button size="large" type="primary" htmlType="submit" style={{ marginTop: '1rem' }}>
                Save
              </Button>
            </Form>
          </Box>
        </Container>
      </>
    );
  };
}

export default Form.create()(BusinessForm);
