import React from 'react';
import styled from 'styled-components';
import { fontFamily, fontWeight, space, color } from 'styled-system';
import propTypes from 'prop-types';
import Flex from './Flex';
import theme from './theme';

const SelectBase = styled.select`
    ${color}
    ${space}
    ${fontWeight}
    ${fontFamily}
    & {
      padding: 0.75rem 1rem;
      margin: 0.25rem 0;
      font-size: ${props => props.fontSize}rem;
      background: #fff;
      box-sizing: border-box;
      height: 50px;
      display: block;
      width: 100%;
      outline: 0;
      font-family: inherit;
      color: ${props => props.textColor};
      border-color: ${props => props.borderColor};
      border-width: 0.125rem;
      border-style: solid;
      border-radius: ${props => props.radius};
      text-indent: 0.75rem;
      &:focus {
        outline: 0;
        border-width: 0.125rem;
        border-style: solid;
        border-color: ${props => props.borderFocusColor};
        box-shadow: inset 0 0 0 1px ${props => props.borderFocusColor};
      }
      & > option {
        color: ${props => props.placeHolderColor};
      }

      & > option[disabled],
      option[disabled][selected] {
          color: ${props => props.placeHolderColor};
        }
      }
`;

SelectBase.defaultProps = {
  theme,
  fontSize: theme.fontSizes.base,
  fontWeight: 400,
  fontFamily: theme.fonts.sansSerif,
  mx: 'auto',
  radius: theme.radius,
  placeholderColor: theme.colors.placeholderColor,
  borderColor: theme.colors.borderColor,
  borderFocusColor: theme.colors.borderFocusColor,
  textColor: theme.colors.textColor,
};

SelectBase.propTypes = {
  ...space.propTypes,
  ...propTypes.fontSize,
  ...propTypes.fontWeight,
  ...propTypes.textAlign,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.borderColor,
};

const Select = styled(props => (
  <Flex width={1} alignItems="center">
    <SelectBase {...props} />
  </Flex>
))``;

Select.isField = true;

export default Select;
