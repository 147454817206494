import styled from 'styled-components';
import { fontFamily, fontWeight, space, color } from 'styled-system';
import propTypes from 'prop-types';

import theme from './theme';

export const Input = styled.input`
    ${color}
    ${space}
    ${fontWeight}
    ${fontFamily}
    & {
      padding: 0.75rem 1rem;
      font-size: ${props => props.fontSize}rem;
      background: #fff;
      box-sizing: border-box;
      width: 100%;
      margin: 0.25rem 0;
      outline: 0;
      height: 50px;
      border-color: ${props => props.borderColor};
      border-width: 0.125rem;
      border-style: solid;
      border-radius: ${props => props.radius};
      color: ${props => props.textColor};
      appearance: none;
      outline: none;
      min-width: 275px;
    }
      &:focus{
        outline: 0;
        border-width: 0.125rem;
        border-style: solid;
        border-color: ${props => props.borderFocusColor};
        box-shadow: inset 0 0 0 1px ${props => props.borderFocusColor};
      }

      ::placeholder {
        color: ${props => props.placeholderColor};
      }
`;

Input.propTypes = {
  ...propTypes.fontSize,
  ...propTypes.fontWeight,
  ...propTypes.textAlign,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.borderColor,
};

Input.defaultProps = {
  theme,
  fontSize: theme.fontSizes.base,
  fontWeight: 400,
  fontFamily: theme.fonts.sansSerif,
  placeholderColor: theme.colors.placeholderColor,
  borderColor: theme.colors.borderColor,
  borderFocusColor: theme.colors.borderFocusColor,
  mx: 'auto',
  radius: theme.radius,
  textColor: theme.colors.textColor,
};

Input.displayName = 'Input';

export default Input;
