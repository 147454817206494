import * as jwt from 'jsonwebtoken';
import { TOKEN, roleValues } from './constants';

const isTokenExpired = token => {
  const decoded = jwt.decode(token);
  if (decoded === undefined || decoded === null) return true;
  const currentTime = Date.now() / 1000;
  return decoded.exp < currentTime;
};

const isAdmin = token => jwt.decode(token).role === roleValues.admin;
const isOwner = token => jwt.decode(token).role === roleValues.owner;
const isManager = token => jwt.decode(token).role === roleValues.manager;
const isUser = token => jwt.decode(token).role === roleValues.user;

export const isTokenValid = token => token !== undefined && !isTokenExpired(token);
export const roleTokenValid = (role, token) => {
  var tokenRole = false;
  switch (role) {
    case roleValues.admin:
      tokenRole = isAdmin(token);
      break;
    case roleValues.owner:
      tokenRole = isOwner(token);
      break;
    case roleValues.manager:
      tokenRole = isManager(token);
      break;
    case roleValues.user:
      tokenRole = isUser(token);
      break;
    default:
      break;
  }

  return isTokenValid(token) && tokenRole;
};
export const getStoredToken = () => localStorage.getItem(TOKEN);
export const delStoredToken = () => localStorage.setItem(TOKEN, undefined);

export const getStoredRole = () => localStorage.getItem('role');
export const getStoredUserId = () => localStorage.getItem('userId');
