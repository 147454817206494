import React from 'react';
import { Select, Spin } from 'antd';
import { getRequest } from '../../utils/network';

const { Option } = Select;

class UserSearchSelect extends React.Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
  }

  state = {
    data: [],
    value: [],
    fetching: false
  };

  fetchUser = value => {
    console.log('fetching user', value);
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    getRequest(`/search?q=${value}&t=users`).then(response => {
      const data = response.data.map(user => ({
        text: `${user.name}`,
        value: `${user.id}`
      }));
      this.setState({ data, fetching: false });
    });
  };

  handleChange = value => {
    this.setState({
      value,
      data: [],
      fetching: false
    });
    console.log(value);
  };

  render() {
    const { fetching, data, value } = this.state;

    return (
      <Select
        showSearch
        size="large"
        labelInValue
        value={value}
        placeholder="Select User"
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={this.fetchUser}
        onChange={this.handleChange}
        style={{ width: '100%' }}
      >
        {data.map(d => (
          <Select.Option key={d.value} value={d.value}>
            {d.text}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default UserSearchSelect;
