import styled from 'styled-components';
import PropTypes from 'prop-types';
import Box from './Box';

const Container = styled(Box)`
  max-width: ${props => props.maxWidth}rem;
  flex-direction: column;
  align-items: center;
`;

Container.displayName = 'Container';

Container.propTypes = {
  maxWidth: PropTypes.number
};

Container.defaultProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mx: 'auto',
  flexDirection: 'column'
};

export default Container;
