import { useEffect, useState } from 'react';
import { getRequest } from '../utils/network';

const useApi = (url, params = {}, initialValue) => {
  const [data, setData] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(
    () => {
      const fetchData = async function() {
        try {
          setIsLoading(true);
          const response = await getRequest(url, {});
          setData(response.data);
        } catch (error) {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    },
    [url],
    {},
  );
  return { data, isLoading, isError };
};

export default useApi;
