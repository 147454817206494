import React from 'react';
import Flex from './Flex';
import { Icon, Layout, Spin } from 'antd';

const { Content } = Layout;
const loadingIcon = <Icon type="loading" style={{ fontSize: 60 }} spin />;

export const Loader = () => (
  <>
    <Content
      style={{
        backgroundColor: 'white',
        width: '100%',
        display: 'flex'
      }}
    >
      <Flex alignItems="center" justifyContent="center">
        <Spin tip="Loading" size="large" indicator={loadingIcon} />
      </Flex>
    </Content>
  </>
);

export default Loader;
