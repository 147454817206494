import styled from 'styled-components';
import { space, width, borderColor } from 'styled-system';
import theme from './theme';

const Divider = styled.hr`
  border: 0;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  ${space}
  ${width}
  ${borderColor};
  display: block;
`;

Divider.displayName = 'Divider';

Divider.defaultProps = {
  theme,
  borderColor: theme.colors.primary,
  ml: 0,
  mr: 0,
  width: '100%',
};

Divider.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...borderColor.propTypes,
};

export default Divider;
