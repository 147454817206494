import styled from 'styled-components';
import { color, fontFamily, fontWeight, space } from 'styled-system';
import theme from './theme';

const SideBarSubtitle = styled.h1`
    ${color}
    ${space}
    ${fontWeight}
    ${fontFamily}

    & {
      font-size: 1rem;
      letter-spacing: -0.0025rem;
      line-height: 1.89;
    }
`;

SideBarSubtitle.defaultProps = {
  theme,
  fontWeight: 600,
  fontFamily: theme.fonts.sansSerifAlt,
  color: theme.colors.primaryDark,
  mx: 'auto',
};

SideBarSubtitle.displayName = 'SideBarSubtitle';

export default SideBarSubtitle;
