import React, { useContext, useState } from 'react';
import { createBrowserHistory } from 'history';
import useApi from '../hooks/useApi';
import { putRequest } from '../utils/network';
import { Avatar, Icon, Button, Row, Col, Divider, Tag, Layout, Table, Result, Spin, notification } from 'antd';
import { Box, Flex, Heading, Loader, Subtitle, Title, Text, theme } from './common';
import { ExtractData, IsNotEmpty, notificationRedirect } from '../utils/helpers';
import { UserContext } from '../contexts/UserContext';

const deepmerge = require('deepmerge');
const moment = require('moment');

const history = createBrowserHistory({
  forceRefresh: true
});

const { Content } = Layout;

const locationsCol = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Location Type',
    dataIndex: 'type'
  },
  {
    title: 'Manager',
    key: 'manager',
    render: (_, record) => {
      if (record.manager != undefined) {
        return `${record.manager.first_name} ${record.manager.last_name}`;
      }
      return null;
    }
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Phone',
    key: 'phone',
    dataIndex: 'phone'
  },
  {
    title: 'Street Address',
    dataIndex: 'address.address_1'
  },
  {
    title: 'City',
    dataIndex: 'address.city'
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (_, location) => (
      <>
        <Button type="link" icon="eye" onClick={() => history.push(`/locations/${location.id}`)} />
        <Divider type="vertical" />
        <Button type="link" icon="mail" onClick={() => (window.location.href = `mailto:${location.contact_email}`)} />
        <Divider type="vertical" />
        <Button type="link" icon="edit" onClick={() => history.push(`/locations/${location.id}/edit`)} />
      </>
    )
  }
];

const BusinessDetail = ({ match }) => {
  const { data, isLoading } = useApi(`/businesses/${match.params.businessId}?employees=true`, {}, {});
  const [dataSource, setDataSource] = useState(data);
  const [error, setError] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isPremiumButtonLoading, setIsPremiumButtonLoading] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const user_role = data.user_role || '';
  // console.log(user_role);

  if (isLoading) {
    return <Loader />;
  }

  let mergeLocations = [],
    locations = [],
    employees = [],
    pendingEmployees = [],
    recs = [],
    generalRecs = [],
    featuredRecs = [];

  Object.assign(employees, data.employees);
  Object.assign(pendingEmployees, data.pending_employees);
  Object.assign(locations, data.locations);

  mergeLocations = deepmerge.all(locations);

  if (IsNotEmpty(employees)) {
    employees = ExtractData(employees);
    pendingEmployees = ExtractData(pendingEmployees);

    employees.forEach(function(employee) {
      employee.isApproved = 'Approved';
    });

    pendingEmployees.forEach(function(employee) {
      employee.isApproved = 'Pending';
    });

    Array.prototype.push.apply(employees, pendingEmployees);
  }

  if (IsNotEmpty(recs)) {
    if (data.owner_id !== '' && data.owner_id != undefined) {
      Object.assign(recs, data.recommendations);
      Object.assign(featuredRecs, data.featured_recommendations);

      Array.prototype.push.apply(recs, featuredRecs);
    }
  }

  if (IsNotEmpty(generalRecs)) {
    Object.assign(generalRecs, data.general_recommendations);
  }

  const displayOwner = owner => `${owner.first_name} ${owner.last_name}`;
  const displayEmployee = employee => `${employee.first_name} ${employee.last_name}`;
  const displayAddress = address => address.split('\\n').join(' ');
  const joinedDisplay = time => `${moment(time).format('MMM DD, YYYY')}`;
  const heroImage = data.image_url ? data.image_url : 'https://propslove.com/images/section-bg1.jpg';
  const avatarImage = data.secondary_image_url ? data.secondary_image_url : '';

  const styles = {
    heroBg: {
      height: '35vh',
      backgroundImage: `url(${heroImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: data.image_url == null ? 'none' : 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-end',
      marginBottom: '1.5rem'
    },
    businessOwnerTitle: {
      fontWeight: 500,
      fontSize: '0.875rem',
      color: theme.colors.blacks[7],
      marginLeft: '0.5rem'
    },
    tagBusinessType: {
      fontWeight: 600,
      verticalAlign: 'middle',
      marginLeft: '0.25rem'
    },
    tagPositionType: {
      fontWeight: 600,
      verticalAlign: 'middle',
      marginLeft: '0.25rem'
    },
    tableTitleContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      padding: '0.5rem 0'
    },
    tableTitleText: {
      marginLeft: 0,
      marginBottom: 0,
      fontWeight: 400
    },
    tableFeaturedBtn: {
      borderColor: 'transparent',
      backgroundColor: 'transparent'
    },
    tableFeaturedIcon: {
      fontSize: '1.5rem'
    },
    businessPremiumMessage: {
      backgroundColor: 'rgba(255, 255, 255, 0.90)',
      position: 'absolute',
      top: '90px',
      width: '100%',
      height: '35vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    propTag: {
      fontSize: 12,
      fontWeight: 600
    }
  };

  const isClaimed = owner_id => (
    <Icon
      type="check-circle"
      style={{ fontSize: '1.125rem' }}
      theme="twoTone"
      twoToneColor={owner_id === null ? theme.colors.moonGray : theme.colors.blue}
    />
  );

  const isPremium = premium => (
    <Icon
      type="dollar"
      style={{ fontSize: '1.125rem' }}
      theme="twoTone"
      twoToneColor={premium ? theme.colors.green : theme.colors.moonGray}
    />
  );

  const setPremium = e => {
    e.preventDefault();
    setIsPremiumButtonLoading(true);
    putRequest(`/businesses/${match.params.businessId}`, {
      premium: true
    }).then(response => {
      setIsPremiumButtonLoading(false);
      const businessId = response.data.id;
      try {
        notificationRedirect('/businesses', businessId);
      } catch (error) {
        console.log(error);
        setError('There was an error updating your business. Please try again.');
      }
    });
  };

  const requestPremium = e => {
    e.preventDefault();
    setIsButtonLoading(true);
    putRequest(`/businesses/${match.params.businessId}/request_premium`)
      .then(response => {
        setIsButtonLoading(false);
        notification.success({
          message: 'Success',
          description: 'We will contact you soon about upgrading your business to premium.',
          duration: 5
        });
      })
      .catch(() => {
        setError('There was an error saving the business. Please check the values and try again.');
      });
  };

  const address = data.address || {};

  const hero = () =>
    data.premium === false && user_role === 50 ? (
      <>
        <Result
          style={styles.businessPremiumMessage}
          icon={<Icon type="alert" theme="twoTone" twoToneColor={theme.colors.secondary} />}
          status="success"
          title="Looks like you don't have a premium business account"
          subTitle="Click the button below to get in touch with the Props team and learn more about the benefits of going premium."
          extra={[
            <Button
              size="large"
              style={{ backgroundColor: theme.colors.secondary, borderColor: 'transparent', fontWeight: 600 }}
              type="primary"
              onClick={requestPremium}
              loading={isButtonLoading}
            >
              Request Details
            </Button>
          ]}
        />
        <Box style={styles.heroBg} />
      </>
    ) : (
      <Box style={styles.heroBg} />
    );

  const avatar = () =>
    avatarImage !== '' ? <Avatar size={100} shape="circle" src={avatarImage} /> : <Avatar size={100} icon="user" />;

  const detailHeader = () => (
    <>
      {hero()}
      <Box pt={[3, 4]} px={[2, 3]}>
        <Flex flexDirection="row" justifyContent="space-even" align="start">
          <Flex style={{ width: 'auto' }} flexDirection="column">
            {avatar()}
            {user.role == 100 || user_role == 50 ? (
              <Button
                size="small"
                style={{ marginTop: '0.5rem' }}
                onClick={() => history.push(`/businesses/${match.params.businessId}/edit`)}
              >
                Edit Business
              </Button>
            ) : null}
          </Flex>
          <Box px={[2, 3]}>
            <Heading>
              {data.name}
              {isClaimed(data.owner_id)} {isPremium(data.premium)}
            </Heading>
            {address.id != '00000000-0000-0000-0000-000000000000' ? (
              <>
                <Text>
                  <Text.span>
                    <Icon type="environment" />
                    <Text.span style={{ marginLeft: '0.75rem' }}>{address.address_1}, </Text.span>
                    <Text.span>{address.address_2}</Text.span>
                  </Text.span>
                </Text>
                <Text style={{ marginLeft: '1.75rem' }}>
                  <Text.span>{address.city}, </Text.span>
                  <Text.span>{address.state} </Text.span>
                  <Text.span>{address.postal_code}</Text.span>
                </Text>
              </>
            ) : null}
          </Box>
          {data.owner != null ? (
            <Box style={{ marginLeft: 'auto' }}>
              <Subtitle style={{ display: 'inline-block', textAlign: 'left' }}>
                {displayOwner(data.owner)}
                <Tag style={styles.tagPositionType}>Owner</Tag>
              </Subtitle>
              <Text>
                <Text.span>
                  <Icon type="mail" key="mail" />
                  <Text.span style={{ marginLeft: '1rem' }}>{data.owner.email}</Text.span>
                </Text.span>
              </Text>
              <Text>
                <Text.span>
                  <Icon type="phone" key="phone" />
                  <Text.span style={{ marginLeft: '1rem' }}>{data.owner.phone}</Text.span>
                </Text.span>
              </Text>
            </Box>
          ) : (
            <Box />
          )}

          {data.premium === false && user.role == 100 ? (
            <Flex style={{ width: 'auto' }} flexDirection="column">
              <Button ghost type="primary" onClick={setPremium} loading={isPremiumButtonLoading}>
                Set Business to Premium
              </Button>
            </Flex>
          ) : null}
        </Flex>
      </Box>
    </>
  );

  const detailLocations = () => (
    <>
      <Box px={[2, 3]}>
        <Row>
          <Col>
            <Table
              style={{ marginBottom: '2rem' }}
              rowKey={record => record.id}
              columns={locationsCol}
              dataSource={locations}
              pagination={{
                defaultPageSize: 50,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200']
              }}
              title={() => (
                <>
                  <div style={styles.tableTitleContainer}>
                    <Title style={styles.tableTitleText}>Locations</Title>
                    <Button
                      size="large"
                      style={{ float: 'right', margin: '0 1rem' }}
                      type="primary"
                      onClick={() => history.push(`/businesses/${match.params.businessId}/add`)}
                    >
                      Add Location
                    </Button>
                  </div>
                </>
              )}
            />
          </Col>
        </Row>
      </Box>
    </>
  );

  const employeesCol = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (text, employee) => displayEmployee(employee.user),
      width: 225
    },
    {
      title: 'Username',
      dataIndex: 'user.username',
      key: 'username',
      width: 200
    },
    {
      title: 'Email',
      dataIndex: 'user.email',
      key: 'email',
      width: 200
    },
    {
      title: 'Phone',
      dataIndex: 'user.phone',
      key: 'phone',
      width: 200
    },
    {
      title: 'Location',
      render: (text, employee) => (
        <>
          <span>
            {employee.location.name}
            <br />
            {employee.location.address_display.length ? (
              <small style={{ fontSize: '70%', fontWeight: 600 }}>
                {displayAddress(employee.location.address_display)}
              </small>
            ) : null}
          </span>
        </>
      ),
      width: 250
    },
    {
      title: 'Status',
      dataIndex: 'isApproved',
      key: 'isApproved',
      sortOrder: 'descend',
      sorter: (a, b) => a.isApproved.localeCompare(b.isApproved),
      width: 100,
      render(text, record) {
        return {
          props: {
            style: { background: record.isApproved === 'Pending' ? 'lightyellow' : 'white' }
          },
          children: <div>{text}</div>
        };
      }
    }
  ];

  const detailEmployees = () => (
    <>
      <Box px={[2, 3]}>
        <Row>
          <Col>
            <Table
              style={{ marginBottom: '2rem' }}
              rowKey={record => record.id}
              columns={employeesCol}
              dataSource={employees}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50']
              }}
              title={() => (
                <>
                  <div style={styles.tableTitleContainer}>
                    <Title style={styles.tableTitleText}>Employees</Title>
                  </div>
                </>
              )}
            />
          </Col>
        </Row>
      </Box>
    </>
  );

  const generalRecsCol = [
    {
      title: 'Location',
      render: (text, { location }) => (
        <>
          <span>
            {location.name}
            <br />
            {location.address_display.length ? (
              <small style={{ fontSize: '70%', fontWeight: 600 }}>{displayAddress(location.address_display)}</small>
            ) : null}
          </span>
        </>
      )
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      width: 675
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => joinedDisplay(text),
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
      width: 225
    },
    {
      title: 'Strengths',
      dataIndex: 'tags',
      render: (text, rec) => {
        const tags = rec.tags;

        return tags != undefined && tags.length > 0 ? (
          <>
            <span>
              {tags.map(tag => {
                return (
                  <Tag style={styles.propTag} key={tag.id}>
                    {tag.name.toUpperCase()}
                  </Tag>
                );
              })}
            </span>
          </>
        ) : (
          <span></span>
        );
      }
    }
  ];

  const recsCol = [
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      key: 'recipient_id',
      render: (text, rec) => {
        return `${rec.Recipient.first_name} ${rec.Recipient.last_name}`;
      }
    },
    {
      title: 'Location',
      render: (text, { location }) => (
        <>
          <span>
            {location.name}
            <br />
            {location.address_display.length ? (
              <small style={{ fontSize: '70%', fontWeight: 600 }}>{displayAddress(location.address_display)}</small>
            ) : null}
          </span>
        </>
      )
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note'
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => joinedDisplay(text),
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix()
    },
    {
      title: 'Strengths',
      key: 'tags.id',
      dataIndex: 'tags',
      render: (text, rec) => {
        const tags = rec.tags;

        return tags != undefined && tags.length > 0 ? (
          <>
            <span>
              {tags.map(tag => {
                return (
                  <Tag style={styles.propTag} key={tag.id}>
                    {tag.name.toUpperCase()}
                  </Tag>
                );
              })}
            </span>
          </>
        ) : (
          <span></span>
        );
      }
    }
  ];

  const detailProps = () => (
    <>
      <Box px={[2, 3]}>
        <Row>
          <Col>
            {data.owner_id === '' || data.owner_id == undefined ? (
              <Table
                style={{ marginBottom: '2rem' }}
                columns={generalRecsCol}
                dataSource={generalRecs}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50']
                }}
                title={() => (
                  <>
                    <div style={styles.tableTitleContainer}>
                      <Title style={styles.tableTitleText}>General Props</Title>
                    </div>
                  </>
                )}
              />
            ) : (
              <>
                <Table
                  style={{ marginBottom: '2rem' }}
                  rowKey={record => record.id}
                  columns={recsCol}
                  dataSource={recs}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50']
                  }}
                  title={() => (
                    <>
                      <div style={styles.tableTitleContainer}>
                        <Title style={styles.tableTitleText}>Props</Title>
                      </div>
                    </>
                  )}
                />
                <Table
                  style={{ marginBottom: '2rem' }}
                  rowKey={record => record.id}
                  columns={generalRecsCol}
                  dataSource={generalRecs}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50']
                  }}
                  title={() => (
                    <>
                      <div style={styles.tableTitleContainer}>
                        <Title style={styles.tableTitleText}>General Props</Title>
                      </div>
                    </>
                  )}
                />
              </>
            )}
          </Col>
        </Row>
      </Box>
    </>
  );

  return (
    <Content
      style={{
        backgroundColor: 'white',
        width: '100%'
      }}
    >
      {detailHeader()}
      <Divider />
      {detailLocations()}
      <Divider />
      {detailEmployees()}
      <Divider />
      {detailProps()}
    </Content>
  );
};

export default BusinessDetail;
