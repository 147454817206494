import React, { useState, createContext } from 'react';
import { getStoredToken, isTokenValid, isAdminTokenValid, getStoredRole, getStoredUserId } from '../utils/auth';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const token = getStoredToken();
  const role = getStoredRole();
  const userId = getStoredUserId();

  const [user, setUser] = useState({
    loggedIn: isTokenValid(token),
    role: JSON.parse(role) || '',
    id: userId || ''
    // need role storage here
    // other useful user info stored here
  });
  return <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>;
};
