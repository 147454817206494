import styled from 'styled-components';
import { color, fontFamily, fontWeight, space } from 'styled-system';
import theme from './theme';

const Title = styled.h1`
    ${color}
    ${space}
    ${fontWeight}
    ${fontFamily}

    & {
      font-size: calc(1.4rem + (4-2) * (100vw - 400px)/(900-400));
      letter-spacing: -0.0025rem;
      line-height: 1.89;
    }
`;

Title.defaultProps = {
  theme,
  fontWeight: 400,
  fontFamily: theme.fonts.sansSerif,
  color: theme.colors.textColor,
  mx: 'auto'
};

Title.displayName = 'Title';

export default Title;
