import styled from 'styled-components';
import { alignSelf, alignItems, display, justifyContent, flexDirection, flex } from 'styled-system';
import propTypes from 'prop-types';
import Box from './Box';

const Flex = styled(Box)`
  display: flex;
  ${alignSelf}
  ${alignItems}
  ${justifyContent}
  ${flexDirection}
  ${flex}
  ${display}
  & {
    min-height: ${props => props.minHeight}rem;
  }
`;

Flex.defaultProps = {};

Flex.propTypes = {
  ...propTypes.alignSelf,
  ...propTypes.alignItems,
  ...propTypes.justifyContent,
  ...propTypes.flexDirection,
  ...propTypes.flex,
  ...propTypes.display,
};

Flex.displayName = 'Flex';

export default Flex;
